// extracted by mini-css-extract-plugin
export var close = "advantages-module--close--XXSvu";
export var count = "advantages-module--count--9LeIQ";
export var hintContainer = "advantages-module--hintContainer--eK0fJ";
export var hintModal = "advantages-module--hintModal--d76GI";
export var icon = "advantages-module--icon--d0uzd";
export var item = "advantages-module--item--UwjbC";
export var items = "advantages-module--items--QSBDR";
export var root = "advantages-module--root--k34ub";
export var sup = "advantages-module--sup--ox0vw";
export var sup2 = "advantages-module--sup2--5Y6n1";
export var text = "advantages-module--text--V9MFs";
export var yourHalvaQr = "advantages-module--yourHalvaQr--86n6b";