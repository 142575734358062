import React, { ReactNode } from "react"

import clsx from "clsx"
import * as styles from "../advantages.module.scss"

type AdvantagesItem = {
  count: ReactNode
  sup: string
  sup2: string
  text1: string
  text2: ReactNode | string
  icon?: ReactNode
}

export const AdvItem = ({ count, sup, sup2, text1, text2, icon }: AdvantagesItem) => (
  <div className={styles.item}>
    <div className={styles.count}>
      {sup2 && <div className={clsx(styles.sup, styles.sup2)}>{sup2}</div>}
      {icon && <div className={styles.icon}>{icon}</div>}
      <div>{count}</div>
      {sup && <div className={styles.sup}>{sup}</div>}
    </div>

    <div className={styles.text}>
      {text1}
      <br />
      {text2}
    </div>
  </div>
)
