import React, { ReactNode } from "react"
import clsx from "clsx"

import Container from "@ecom/ui/components/Container"

import * as styles from "./advantages.module.scss"
import { OTHER_ITEMS, positions } from "./helpers"
import { AdvItemWithTooltip } from "./AdvItems/AdvItemWithTooltip"
import { AdvItem } from "./AdvItems/AdvItem"

export type AdvantagesItem = {
  count: React.ReactNode
  sup: string
  sup2: string
  text1: string
  text2: ReactNode | string
  hint?: ReactNode
  icon?: ReactNode
}

type Props = {
  items?: AdvantagesItem[]
  orderNum?: string
  variantPosition?: "yourHalvaQr"
}

export default function Advantages({ items = OTHER_ITEMS, orderNum, variantPosition }: Props) {
  const advantageRender = items.map(({ count, sup, sup2, text1, text2, hint, icon }, i) =>
    hint ? (
      <AdvItemWithTooltip
        key={i}
        count={count}
        icon={icon}
        sup={sup}
        sup2={sup2}
        text1={text1}
        text2={text2}
        hint={hint}
        position={positions[i]}
      />
    ) : (
      <AdvItem
        key={i}
        sup2={sup2}
        count={count}
        sup={sup}
        text1={text1}
        text2={text2}
        icon={icon}
      />
    )
  )

  return (
    <div data-exclude={orderNum}>
      <Container className={styles.root}>
        <div className={clsx(styles.items, variantPosition && styles[variantPosition])}>
          {advantageRender}
        </div>
      </Container>
    </div>
  )
}
