import React, { ReactNode } from "react"
import clsx from "clsx"
import { withStyles } from "@material-ui/styles"
import { Theme, Zoom } from "@material-ui/core"
import Tooltip from "@material-ui/core/Tooltip"
import ClickAwayListener from "@material-ui/core/ClickAwayListener"

import close_icon from "../../../images/icons/icon_close.svg"

import * as styles from "../advantages.module.scss"

interface AdvWithTooltip {
  count: ReactNode
  sup: string
  sup2: string
  text1: string
  text2: ReactNode | string
  hint: ReactNode
  position: Record<string, any>
  icon?: ReactNode
}

export function AdvItemWithTooltip({
  count,
  sup,
  sup2,
  text1,
  text2,
  hint,
  position,
  icon,
}: AdvWithTooltip) {
  const [open, setOpen] = React.useState(false)

  const handleTooltipClose = () => {
    setOpen(false)
  }

  const handleTooltipOpen = () => {
    setOpen(true)
  }

  const HtmlTooltip = withStyles((theme: Theme) => ({
    tooltip: {
      left: position!.xs.left,
      color: "#000",
      position: "absolute",
      pointerEvents: "auto",
      cursor: "default",
      padding: "24px 16px",
      background: "#ffffff",
      border: "1px solid #cdcdcd",
      boxSizing: "border-box",
      boxShadow: " 0px 8px 14px rgba(122, 122, 122, 0.25)",
      borderRadius: 8,
      width: 250,
      transition: " 0.3s all",
      marginTop: 16,

      "&::after": {
        right: position!.xs.afterRight,
        borderTop: "none",
        content: "''",
        position: "absolute",
        bottom: "99%",
        border: "0.75rem solid transparent",
        borderBottomColor: "#ffffff",
        filter: "drop-shadow(0 -0.0425rem 0 rgba(122, 122, 122, 0.3))",
      },

      [theme.breakpoints.up("sm")]: {
        left: position!.sm.left,
        "&::after": {
          right: position!.sm.afterRight,
        },
      },

      [theme.breakpoints.up("md")]: {
        left: position!.md.left,
        "&::after": {
          right: position!.md.afterRight,
        },
      },

      [theme.breakpoints.up("lg")]: {
        left: position!.lg.left,
        "&::after": {
          right: position!.lg.afterRight,
        },
      },
    },
  }))(Tooltip)
  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div>
        <HtmlTooltip
          PopperProps={{
            disablePortal: false,
          }}
          onClose={handleTooltipOpen}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={
            <>
              <div className={styles.hintModal}>{hint}</div>
              <div className={styles.close} onClick={handleTooltipClose} aria-hidden="true">
                <img src={close_icon} alt="close" />
              </div>
            </>
          }
          TransitionComponent={Zoom}
        >
          <div className={styles.hintContainer} onClick={handleTooltipOpen} aria-hidden="true">
            <div className={styles.item}>
              <div className={styles.count}>
                {sup2 && <span className={clsx(styles.sup, styles.sup2)}>{sup2}</span>}
                {icon}
                {count}
                {sup && <span className={styles.sup}>{sup}</span>}
              </div>
              <div className={styles.text}>
                {text1}
                <br />
                {text2}
              </div>
            </div>
          </div>
        </HtmlTooltip>
      </div>
    </ClickAwayListener>
  )
}
